.cooler-circle {
  fill: #cccccc;
 transition: fill 500ms;
}
.flakes {
  fill: #f9f9f9;
 transition: fill 500ms;
}

.active .cooler-circle {
  fill: #52C8FF;
}
.active .flakes {
  fill: #FFFFFF;
}
