.heater-circle {
  fill: #cccccc;
 transition: fill 500ms;
}
.flame-outer {
  fill: #999999;
 transition: fill 500ms;
}
.flame-middle {
  fill: #cccccc;
  opacity: 0.8;
 transition: fill 500ms;
}
.flame-inner {
  fill: #f9f9f9;
  opacity: 0.8;
 transition: fill 500ms;
}

.active .heater-circle {
  fill: #E3AA24;
}
.active .flame-outer {
  fill: #E17A2D;
}
.active .flame-middle {
  fill: #F4E028;
}
.active .flame-inner {
  fill: #FFFFFF;
}
